//
// Listing
// --------------------------------------------------

.listing {
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none !important;
  }
  // Reset (un)ordered listing inside a listing
  ul, ol {
    margin-bottom: @base-line-height;
    ul, ol, dl {
      margin-bottom: 0;
      margin-top: 2px;
    }
    ul {
      list-style-type: circle;
    }
  }
  ul {
    list-style-type: disc;
  }
  .image {
    position: relative;
    z-index: 4;
    img {
      display: block;
      height: auto;
      max-width: 100%;
      width: auto;
    }
  }
  .list-item {
    .clearfix;
  }
}

// CHECKS
.listing-checks {
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
  .list-item {
    .fontBold;
    line-height: 1.1764705882em;
    padding: 10px 0 10px 40px;
    position: relative;
  }
  .icon-check {
    color: @color-secondary;
    font-size: 1.55rem;
    left: 0;
    position: absolute;
    top: 5px;
  }
  .row-home & {
    max-width: 400px;
    .list-item {
      font-size: 1.1176470588rem;
      padding-top: 11px;
      padding-bottom: 11px;
    }
    .icon-check {
      top: 9px;
    }
  }
}

// REWARDS / BELONINGEN
.listing-rewards {
  .flex-align-items(center);
  .flex-display(flex);
  .flex-wrap(wrap);
  margin: 2rem auto;
  text-align: center;
  .list-item {
    padding: 8px 5px;
    width: 20%;
    img {
      height: auto;
      max-width: 100%;
      width: auto;
    }
    &.is-star {
      color: @color-secondary;
      display: inline-block;
      font-size: 1rem;
      .fontBold;
      width: auto;
      img {
        width: 50px;
      }
      .star {
        display: inline-block;
        margin-left: 2px;
        position: relative;
      }
      span {
        color: @white;
        left: 50%;
        line-height: 1em;
        position: absolute;
        top: 50%;
        .translate(-50%, -40%);
      }
    }
  }
}

// RESULTS
.listing-results {
  margin-top: 15px;
  margin-bottom: 15px;
  .list-item {
    line-height: 1.4705882353em;
    margin-bottom: 25px;
    padding-left: 32px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  em {
    color: #959595;
  }
  .icon {
    background-color: @color-primary;
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 20px;
    .border-radius(20px);
    &.icon-previous {
      background-color: #b7b7b7;
      .opacity(0.5);
    }
  }
}

// SCORES
.listing-scores {
  margin-top: 1.4117647059rem;
  .list-item {
    margin-bottom: 1.5294117647em;
  }
  h4 {
    font-size: 1rem;
    margin-bottom: 0.7647058824em;
  }
  .graph {
    position: relative;
    .graph-line {
      background: rgb(240,58,25);
      background: -moz-linear-gradient(left,  rgba(240,58,25,1) 0%, rgba(247,148,29,1) 35%, rgba(141,198,63,1) 69%, rgba(57,181,74,1) 100%);
      background: -webkit-linear-gradient(left,  rgba(240,58,25,1) 0%,rgba(247,148,29,1) 35%,rgba(141,198,63,1) 69%,rgba(57,181,74,1) 100%);
      background: linear-gradient(to right,  rgba(240,58,25,1) 0%,rgba(247,148,29,1) 35%,rgba(141,198,63,1) 69%,rgba(57,181,74,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f03a19', endColorstr='#39b54a',GradientType=1 );
      height: 9px;
      .border-radius(9px);
    }
    .graph-result {
      background-color: @color-primary;
      display: block;
      height: 20px;
      left: 0;
      position: absolute;
      top: -6px;
      width: 20px;
      z-index: 2;
      .border-radius(20px);
      .translate(-10px, 0);
      &.graph-result-previous {
        background-color: #b7b7b7;
        z-index: 1;
        .opacity(0.5);
      }
    }
  }
}

// THEMA'S
.listing-themas {
  padding: 17px 0;
  .list-item {
    padding: 3px 10px;
    &.is-active a {
      background-color: @color-primary;
      color: @white;
      .icon {
        .improvement-indicator {
          border-color: @color-primary;
        }
      }
      .icon-chevron-right {
        color: inherit;
        .opacity(0.4);
      }
      h5 {
        .fontBold;
      }
    }
  }
  a {
    .flex-align-items(center);
    .flex-display(flex);
    padding: 8px 35px 8px 12px;
    position: relative;
    .border-radius(100px);
    -webkit-transition: background-color .15s ease, color .15s ease;
    -o-transition: background-color .15s ease, color .15s ease;
    transition: background-color .15s ease, color .15s ease;
    &:hover {
      background-color: @color-tertiary;
    }
  }
  .icon {
    margin-right: 16px;
    position: relative;
    text-align: center;
    width: 36px;
    i {
      color: @color-secondary;
      font-size: 2rem;
      &.icon-alcohol,
      &.icon-dieet {
        font-size: 1.8rem;
      }
    }
    .improvement-indicator {
      background-color: @color-secondary;
      border: 2px solid @white;
      color: @white;
      font-size: 0.8235294118rem;
      .fontSemibold;
      height: 21px;
      left: -5px;
      line-height: 1em;
      padding: 1px 0;
      position: absolute;
      top: 50%;
      width: 21px;
      .border-radius(21px);
      .transition(border-color .15s ease);
      .translate(0, -50%);
    }
  }
  h5 {
    color: inherit;
    .fontRegular;
    font-size: 1.2941176471rem;
    margin-bottom: 0;
  }
  .icon-chevron-right {
    color: @black;
    right: 12px;
    position: absolute;
    top: 50%;
    .opacity(0.25);
    .transition(opacity .15s ease-in-out);
    .translate(0, -50%);
  }
  .is-hold-on & {
    .icon {
      i {
        color: @color-success;
      }
    }
  }
  .no-flexbox & {
    a {
      display: block;
    }
    .icon, h5 {
      .inline-block();
      vertical-align: middle;
    }
  }
}

@media (min-width: 420px) {
  .listing-rewards {
    .list-item {
      width: 16.6666666667%;
    }
  }
}

@media (min-width: 600px) {
  .listing-rewards {
    .list-item {
      width: 14.2857142857%;
      &.is-star {
        img {
          width: 60px;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .listing-rewards {
    .list-item {
      width: 12.5%;
    }
  }
}

@media (min-width: @bp-medium) {
  .listing-themas {
    .flex-display(flex);
    .flex-wrap(wrap);
    .list-item {
      width: 50%;
    }
    .no-flexbox & {
      .spacing();
      .list-item {
        .inline-block();
        vertical-align: top;
        width: ~"calc(50% - 20px)";
        .spacing-normal();
      }
    }
  }
}