//
// Content
// --------------------------------------------------

.page-content,
#subcontent {
  background-color: @white;
  .flexbox & {
    .flex(1 0 auto);
    &.no-flex-grow {
      .flex(0 1 auto);
    }
  }
}
.bg-primary {
  background-color: @color-primary;
}
.bg-tertiary {
  background-color: @color-tertiary;
}

.mainContent {
  padding-top: 15px;
  padding-bottom: 15px;
  h3 {
    color: @color-secondary;
  }
  h4 {
    margin-bottom: 2px;
  }
  .articleContent {
    a {
      text-decoration: underline;
    }
    .btn {
      text-decoration: none;
    }
    ul, ol {
      margin-left: 1.6em;
      padding: 0;
      ul, ol {
      }
    }
  }
  p.source {
    font-size: 0.8235294118rem;
  }
}

.alert {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
  max-width: 500px;
}

.date {
  .fontCursive;
  margin-bottom: 40px;
  span {
    display: block;
    line-height: 1em;
  }
  .day {
    font-size: 5.8823529412rem;
  }
  .month {
    color: @color-secondary;
    font-size: 1.9411764706rem;
    text-transform: lowercase;
  }
}
.noot-content {
  margin-bottom: 40px;
  a {
    display: block;
    text-decoration: none;
  }
  .noot-full {
    transform: translatey(0px);
    margin-left: auto;
    margin-right: auto;
    height: 189px;
    position: relative;
    width: 251px;
    animation: float 6s ease-in-out infinite;
    img {
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 2;
      .transform-origin(left center);
      &.noot-bottom {
        z-index: 1;
      }
    }
    &.is-playing {
      .noot-top {
        animation: noot-top 1.248s ease-in-out;
        animation-fill-mode: forwards
      }
      .noot-bottom {
        animation: noot-bottom 1.248s ease-in-out;
        animation-fill-mode: forwards
      }
    }
    &.has-played {
      .noot-top{
        transform: translate(-2px, 10px) rotate(-7.25deg);
      }
      .noot-bottom {
        transform: translate(3px, 13px) rotate(1.11deg);
      }
    }
  }
  .noot-shadow {
    transform: scale(1);
    opacity: 1;
    animation: float-shadow 6s ease-in-out infinite;
  }
  + .btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
  }
}
.thema-section {
  h3 {
    background-color: @color-secondary;
    color: @white;
    font-size: 1rem;
    margin-bottom: 0;
    padding: 9px 25px;
  }
  &.is-hold-on {
    h3 {
      background-color: @color-success;
    }
  }
}
.nootHead {
  margin-bottom: @base-line-height;
  text-align: center;
  .noot-type {
    color: @color-secondary;
    .fontCursive;
    font-size: 1rem;
    margin-bottom: 6px;
    text-transform: lowercase;
  }
  .title {
    font-size: 1.2941176471rem;
  }
}

.noot-gekraakt {
  .nootHead {
    margin-bottom: 1rem;
    .title {
      font-size: 1.7647058824rem;
      i[class^="icon-"],
      i[class*=" icon-"] {
        color: @color-secondary;
        margin-right: 12px;
      }
    }
  }
  .articleContent {
    p {
      margin-bottom: 1rem;
    }
  }
}

.beloning {
  .themaHead {
    padding-top: 1rem;
    text-align: center;
    .title {
      color: @color-secondary;
      font-size: 1.6470588235rem;
      margin-bottom: 0.3928571429em;
      i {
        margin-right: 10px;
      }
    }
  }
  .rewards-info {
    margin-top: 1rem;
    text-align: center;
    p {
      .highlighted {
        color: @color-secondary;
        .fontBold;
      }
      .btn {
        color: @color-secondary !important;
      }
    }
  }
}

@media (min-width: @bp-small) {
  .mainContent {
    padding-top: 30px;
    padding-bottom: 30px;
    p.source {
      font-size: 0.9411764706rem;
    }
  }
  .nootHead {
    .title {
      font-size: 1.7rem;
    }
  }
}