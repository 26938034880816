//
// Buttons
// --------------------------------------------------

// GLOBAL
.btn,
.btn:active,
.btn:active:focus,
.btn:focus {
  border: none;
  .fontBold;
  font-size: 1rem;
  padding: 8px 25px;
  outline: 0;
  text-decoration: none !important;
  .box-shadow(none);
}

// BLACK TO WHITE
.btn-black,
.btn-black:active,
.btn-black:active:focus,
.btn-black:focus {
  background-color: @black;
  border-color: @black;
  color: @white !important;
  &:hover {
    background-color: lighten(@black ,5%);
    border-color: @black;
  }
}

.btn-gray-darker,
.btn-gray-darker:active,
.btn-gray-darker:active:focus,
.btn-gray-darker:focus {
  background-color: @gray-darker;
  border-color: darken(@gray-darker, 7%);
  color: @white !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: lighten(@gray-darker, 5%);
    border-color: darken(@gray-darker, 14%);
  }
}

.btn-gray-dark,
.btn-gray-dark:active,
.btn-gray-dark:active:focus,
.btn-gray-dark:focus {
  background-color: @gray-dark;
  border-color: darken(@gray-dark, 7%);
  color: @white !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  &:hover {
    background-color: lighten(@gray-dark, 5%);
    border-color: darken(@gray-dark, 14%);
  }
}

.btn-gray,
.btn-gray:active,
.btn-gray:active:focus,
.btn-gray:focus {
  background-color: @gray;
  border-color: darken(@gray, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@gray, 5%);
    border-color: darken(@gray, 14%);
  }
}

.btn-gray-light,
.btn-gray-light:active,
.btn-gray-light:active:focus,
.btn-gray-light:focus {
  background-color: @gray-light;
  border-color: darken(@gray-light, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@gray-light, 5%);
    border-color: darken(@gray-light, 14%);
  }
}

.btn-gray-lighter,
.btn-gray-lighter:active,
.btn-gray-lighter:active:focus,
.btn-gray-lighter:focus {
  background-color: @gray-lighter;
  border-color: darken(@gray-lighter, 7%);
  color: @black !important;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.2);
  &:hover {
    background-color: darken(@gray-lighter, 5%);
    border-color: darken(@gray-lighter, 14%);
  }
}

.btn-white,
.btn-white:active,
.btn-white:active:focus,
.btn-white:focus {
  background-color: @white;
  border-color: darken(@white, 15%);
  color: @black !important;
  text-shadow: none;
  &:hover {
    background-color: darken(@white, 5%);
    border-color: darken(@white, 19%);
  }
}

// SOCIAL MEDIA
.btn-facebook,
.btn-facebook:active,
.btn-facebook:active:focus,
.btn-facebook:focus {
  background-color: @color-facebook;
  border-color: darken(@color-facebook, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-facebook, 5%);
    border-color: darken(@color-facebook, 14%);
  }
}

.btn-instagram,
.btn-instagram:active,
.btn-instagram:active:focus,
.btn-instagram:focus {
  background-color: @color-instagram;
  border-color: darken(@color-instagram, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-instagram, 5%);
    border-color: darken(@color-instagram, 14%);
  }
}

.btn-linkedin,
.btn-linkedin:active,
.btn-linkedin:active:focus,
.btn-linkedin:focus {
  background-color: @color-linkedin;
  border-color: darken(@color-linkedin, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-linkedin, 5%);
    border-color: darken(@color-linkedin, 14%);
  }
}

.btn-rss,
.btn-rss:active,
.btn-rss:active:focus,
.btn-rss:focus {
  background-color: @color-rss;
  border-color: darken(@color-rss, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-rss, 5%);
    border-color: darken(@color-rss, 14%);
  }
}

.btn-twitter,
.btn-twitter:active,
.btn-twitter:active:focus,
.btn-twitter:focus {
  background-color: @color-twitter;
  border-color: darken(@color-twitter, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-twitter, 5%);
    border-color: darken(@color-twitter, 14%);
  }
}

.btn-youtube,
.btn-youtube:active,
.btn-youtube:active:focus,
.btn-youtube:focus {
  background-color: @color-youtube;
  border-color: darken(@color-youtube, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-youtube, 5%);
    border-color: darken(@color-youtube, 14%);
  }
}

// COLOURS
.btn-primary,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:focus {
  background-color: @color-primary;
  border-color: darken(@color-primary, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-primary, 5%);
    border-color: darken(@color-primary, 14%);
  }
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:active:focus,
.btn-secondary:focus {
  background-color: @color-secondary;
  border-color: darken(@color-secondary, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-secondary, 5%);
    border-color: darken(@color-secondary, 14%);
  }
}

.btn-tertiary,
.btn-tertiary:active,
.btn-tertiary:active:focus,
.btn-tertiary:focus {
  background-color: @color-tertiary;
  border-color: darken(@color-tertiary, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-tertiary, 5%);
    border-color: darken(@color-tertiary, 14%);
  }
}

.btn-quaternary,
.btn-quaternary:active,
.btn-quaternary:active:focus,
.btn-quaternary:focus {
  background-color: @color-quaternary;
  border-color: darken(@color-quaternary, 7%);
  color: @white !important;
  &:hover {
    background-color: darken(@color-quaternary, 5%);
    border-color: darken(@color-quaternary, 14%);
  }
}

.btn-quinary,
.btn-quinary:active,
.btn-quinary:active:focus,
.btn-quinary:focus {
  background-color: @color-quinary;
  border-color: darken(@color-quinary, 7%);
  color: @color-secondary !important;
  &:hover {
    background-color: darken(@color-quinary, 5%);
    border-color: darken(@color-quinary, 14%);
  }
}

.btn-transparent,
.btn-transparent:active,
.btn-transparent:active:focus,
.btn-transparent:focus {
  background-color: transparent;
  color: @color-secondary;
  padding: 6px 10px;
  &:hover {
    color: @color-secondary;
  }
}

// ICONS
.btn-icon,
.btn-icon:active,
.btn-icon:active:focus,
.btn-icon:focus {
  padding-left: 12px;
  padding-right: 12px;
  i, span {
    display: inline-block;
    vertical-align: middle;
  }
  i {
    font-size: 1.7647058824rem;
    margin-right: 12px;
    &.icon-chevron-left,
    &.icon-chevron-down,
    &.icon-chevron-up,
    &.icon-chevron-right {
      font-size: 1.3rem;
    }
  }
  &.btn-icon-white {
    i {
      color: @white;
    }
  }
}

// EDIT
.btn-edit {
  background: url('../../../ccity_base_theme/assets/images/drupal7-buttons.png');
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4;
  color: @gray-dark !important;
  cursor: pointer;
  .inline-block();
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: 13px;
  margin: 5px 0;
  padding: 4px 16px;
  text-align: center;
  text-decoration: none;
  .border-radius(20px);
  .transition(all .1s ease);
  &:hover {
    border-color: #b4b4b4;
    background-position: left -40px;
    color: #222222;
    .box-shadow(1px 1px 2px rgba(0, 0, 0, 0.2) inset);
  }
}